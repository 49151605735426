/**
 * page top
 */

export function scrollPageTop() {
  const pageTop = document.querySelector('.page-top');

  pageTop.addEventListener('click', scrollToTop);

  function scrollToTop() {
    window.scroll({top: 0, behavior: 'smooth'});
  }
}