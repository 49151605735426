export function tabControl() {
  const tabs = document.getElementsByClassName('js-tab');
  for(let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', tabSwitch, false);
  }

  function tabSwitch(){
    document.getElementsByClassName('is-active')[0].classList.remove('is-active');
    this.classList.add('is-active');

    document.getElementsByClassName('is-show')[0].classList.remove('is-show');

    const arrayTabs = Array.prototype.slice.call(tabs);
    const idx = arrayTabs.indexOf(this);
    document.getElementsByClassName('js-panel')[idx].classList.add('is-show');
  }
}