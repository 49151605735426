import '../scss/styles.scss'

import {drawerToggle} from "./modules/drawerToggle";
import {smoothScroll} from "./modules/smoothScroll";
import {scrollPageTop} from "./modules/scrollPageTop";
import {tabControl} from "./modules/tabControl";
import {addBodyMarginTop} from "./modules/addBodyMarginTop";
import {headerScrollY} from "./modules/headerScrollY";
// import {hideScrollHeader} from "./modules/hideScrollHeader";
// import {setFitHeight} from "./modules/setFillHeight";

window.addEventListener('load', () => {
  drawerToggle();
  smoothScroll();
  scrollPageTop();
  tabControl();
  addBodyMarginTop();
  headerScrollY();
})

window.addEventListener('resize', () => {
  addBodyMarginTop();
  smoothScroll();
})